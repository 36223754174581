<template>
  <div>
    <validation-observer ref="updateOrderForm" #default="{ invalid }">
      <!-- User Info: Input Fields -->
      <b-form>
        <!-- Grunddaten -->
        <b-row>
          <b-col cols="12">
            <h4>Grunddaten - Auftrag #{{ orderData.Auftragsnummer }}</h4>
          </b-col>

          <!-- Grunddaten - Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="status">
              <v-select
                v-model="orderData.Status"
                id="status"
                :reduce="status => status.value"
                :clearable="false"
                :options="[
                  {
                    label: 'Warte auf Freigabe',
                    value: 'Schwebend',
                    color: 'secondary',
                  },
                  { label: 'Bestätigt', value: 'Bestaetigt', color: 'info' },
                  { label: 'Bereit', value: 'Bereit', color: 'success' },
                  { label: 'Laufend', value: 'Laufend', color: 'dark' },
                  { label: 'Beendet', value: 'Beendet', color: 'primary' },
                  {
                    label: 'Abgebrochen',
                    value: 'Abgebrochen',
                    color: 'danger',
                  },
                  { label: 'Ruht', value: 'Ruht', color: 'warning' },
                ]"
              >
                <template #selected-option="{ label, color }">
                  <b-badge :variant="color">
                    {{ label }}
                  </b-badge>
                </template>
                <template #option="{ label, color }">
                  <b-badge :variant="color">
                    {{ label }}
                  </b-badge>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Grunddaten - Bestellnummer -->
          <b-col cols="12" md="4">
            <b-form-group label="Bestellnummer" label-for="bestellnummer">
              <b-form-input
                id="bestellnummer"
                v-model="orderData.Bestellnummer"
              />
            </b-form-group>
          </b-col>

          <!-- Grunddaten - Mitarbeiterbedarf -->
          <b-col cols="12" md="4">
            <b-form-group
              label="Mitarbeiterbedarf"
              label-for="mitarbeiterbedarf"
            >
              <validation-provider
                #default="{ errors }"
                name="Mitarbeiterbedarf"
                vid="mitarbeiterbedarf"
                rules="between:1,20"
              >
                <b-form-input
                  id="mitarbeiterbedarf"
                  v-model="orderData.Auftragskonditionen.Mitarbeiterbedarf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Grunddaten - Stundensatz -->
          <b-col cols="12" md="4">
            <b-form-group label="Stundensatz" label-for="stundensatz">
              <validation-provider
                #default="{ errors }"
                name="Stundensatz"
                vid="stundensatz"
                rules="between:1,500"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="stundensatz"
                    v-model="orderData.Auftragskonditionen.Stundensatz"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Grunddaten - Anfahrtskosten -->
          <b-col cols="12" md="4">
            <b-form-group label="Anfahrtskosten" label-for="anfahrtskosten">
              <validation-provider
                #default="{ errors }"
                name="Anfahrtskosten"
                vid="anfahrtskosten"
                rules="between:1,200"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="anfahrtskosten"
                    v-model="orderData.Auftragskonditionen.Anfahrtskosten"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Auftragszeitraum -->
          <b-col cols="12" md="4">
            <b-form-group
              label="Auftragszeitraum*"
              label-for="auftragszeitraum"
            >
              <validation-provider
                #default="{ errors }"
                name="Auftragszeitraum"
                vid="auftragszeitraum"
                rules="required"
              >
                <flat-pickr
                  id="auftragszeitraum"
                  v-model="orderDateRange"
                  :config="flatPickrConfig"
                  placeholder="Auftragszeitraum festlegen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <!-- Kunde -->
          <b-col cols="12">
            <h4>Kunde</h4>
          </b-col>

          <!-- Kunde - Kunde -->
          <b-col cols="12" md="4">
            <b-form-group label="Kunde" label-for="kunde">
              <v-select
                v-model="orderData.Kunde.id"
                id="kunde"
                :clearable="false"
                :options="customers"
                :reduce="customer => customer.id"
                label="Firma"
              >
                <template slot="option" slot-scope="option">
                  <b-media vertical-align="center" class="align-items-center">
                    <template #aside>
                      <b-avatar
                        class="kunde-avatar"
                        size="40"
                        :src="
                          option.User.avatar
                            ? $dbBaseUrl +
                              option.User.avatar.formats.thumbnail.url
                            : ''
                        "
                        :text="`${option.Firma.charAt()}`"
                        :variant="`light-secondary`"
                        :to="{
                          name: 'kunde-ansicht',
                          params: { id: option.id },
                        }"
                        rounded
                      />
                    </template>
                    {{ option.Firma }}
                  </b-media>
                </template>
                <template #no-options="{ search, searching, loading }">
                  {{ `Es wurden leider keine Kunden gefunden.` }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Kunde - Ansprechpartner -->
          <b-col cols="12" md="4">
            <b-form-group label="Ansprechpartner" label-for="ansprechpartner">
              <v-select
                v-model="orderData.Ansprechpartner_ID"
                id="ansprechpartner"
                :clearable="false"
                :options="ansprechpartner"
                :reduce="contactPerson => contactPerson.id"
                label="Nachname"
              >
                <template slot="selected-option" slot-scope="option"
                  >{{ option.Vorname }} {{ option.Nachname }}</template
                >
                <template slot="option" slot-scope="option"
                  >{{ option.Vorname }} {{ option.Nachname }}</template
                >
                <template #no-options="{ search, searching, loading }">
                  {{ `Es wurden leider keine Ansprechpartner gefunden.` }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort -->
          <b-col cols="12">
            <b-form-checkbox
              class="mb-50"
              :id="`abweichender-auftragsort`"
              v-model="locationToggle"
              :name="`abweichender-auftragsort`"
              switch
            >
              Auftragsort weicht von Kundenadresse ab?
            </b-form-checkbox>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort - Straße -->
          <b-col cols="12" md="4" v-if="locationToggle">
            <b-form-group
              label="Abweichender Auftragsort - Straße"
              label-for="abweichender-auftragsort-strasse"
            >
              <b-form-input
                id="abweichender-auftragsort-strasse"
                v-model="orderData.Abweichender_Auftragsort.Strasse"
                placeholder="Musterstraße"
              />
            </b-form-group>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort - Hausnummer -->
          <b-col cols="12" md="4" v-if="locationToggle">
            <b-form-group
              label="Abweichender Auftragsort - Hausnummer"
              label-for="abweichender-auftragsort-hausnummer"
            >
              <b-form-input
                id="abweichender-auftragsort-hausnummer"
                v-model="orderData.Abweichender_Auftragsort.Hausnummer"
                placeholder="1"
              />
            </b-form-group>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort - Adresszusatz -->
          <b-col cols="12" md="4" v-if="locationToggle">
            <b-form-group
              label="Abweichender Auftragsort - Adresszusatz"
              label-for="abweichender-auftragsort-adresszusatz"
            >
              <b-form-input
                id="abweichender-auftragsort-adresszusatz"
                v-model="orderData.Abweichender_Auftragsort.Zusatz"
                placeholder="Postfach"
              />
            </b-form-group>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort - PLZ -->
          <b-col cols="12" md="4" v-if="locationToggle">
            <b-form-group
              label="Abweichender Auftragsort - PLZ"
              label-for="abweichender-auftragsort-plz"
            >
              <b-form-input
                id="abweichender-auftragsort-plz"
                v-model="orderData.Abweichender_Auftragsort.PLZ"
                placeholder="12345"
              />
            </b-form-group>
          </b-col>

          <!-- Kunde - Abweichender Auftragsort - Stadt -->
          <b-col cols="12" md="4" v-if="locationToggle">
            <b-form-group
              label="Abweichender Auftragsort - Stadt"
              label-for="abweichender-auftragsort-stadt"
            >
              <b-form-input
                id="abweichender-auftragsort-stadt"
                v-model="orderData.Abweichender_Auftragsort.Stadt"
                placeholder="Musterstadt"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <!-- Prüfpositionen -->
          <b-col cols="12">
            <h4>Prüfpositionen</h4>
          </b-col>

          <!-- Prüfpositionen - Prüfpositionen-->
          <b-col
            cols="12"
            md="12"
            class="mb-1"
            v-for="(pruefposition, index) in orderData.Pruefpositionen"
            :key="pruefposition.id"
          >
            <b-card class="mb-0 position-relative details-card">
              <b-button
                v-if="pruefposition.new"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-delete-pruefposition position-absolute"
                @click="deletePruefposition(index)"
              >
                <feather-icon icon="TrashIcon" class="mr-25" />
              </b-button>

              <b-row>
                <!-- Prüfposition -->
                <b-col cols="12">
                  <h5>Prüfposition {{ index + 1 }}</h5>
                </b-col>
                <!-- Pruefposition - Status -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Status"
                    :label-for="`status-pruefposition-${index + 1}`"
                  >
                    <v-select
                      v-model="pruefposition.Status"
                      :id="`status-pruefposition-${index + 1}`"
                      :reduce="status => status.value"
                      :clearable="false"
                      :options="[
                        { label: 'Bereit', value: 'Bereit', color: 'success' },
                        { label: 'Laufend', value: 'Laufend', color: 'dark' },
                        {
                          label: 'Beendet',
                          value: 'Beendet',
                          color: 'primary',
                        },
                        {
                          label: 'Abgebrochen',
                          value: 'Abgebrochen',
                          color: 'danger',
                        },
                        { label: 'Ruht', value: 'Ruht', color: 'warning' },
                      ]"
                    >
                      <template #selected-option="{ label, color }">
                        <b-badge :variant="color">
                          {{ label }}
                        </b-badge>
                      </template>
                      <template #option="{ label, color }">
                        <b-badge :variant="color">
                          {{ label }}
                        </b-badge>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Bauteilgruppe -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Bauteilgruppe"
                    :label-for="`pruefposition-bauteilgruppe-${index + 1}`"
                  >
                    <b-form-input
                      :id="`pruefposition-bauteilgruppe-${index + 1}`"
                      v-model="pruefposition.Bauteilgruppe"
                      placeholder="Bauteilgruppe"
                    />
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Bezeichnung -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Bezeichnung"
                    :label-for="`pruefposition-bezeichnung-${index + 1}`"
                  >
                    <b-form-input
                      :id="`pruefposition-bezeichnung-${index + 1}`"
                      v-model="pruefposition.Bezeichnung"
                      placeholder="Bezeichnung"
                    />
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Artikelnummer -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Artikelnummer"
                    :label-for="`pruefposition-artikelnummer-${index + 1}`"
                  >
                    <b-form-input
                      :id="`pruefposition-artikelnummer-${index + 1}`"
                      v-model="pruefposition.Artikelnummer"
                      placeholder="#####"
                    />
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Chargennummer -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Chargennummer"
                    :label-for="`pruefposition-chargennummer-${index + 1}`"
                  >
                    <b-form-input
                      :id="`pruefposition-chargennummer-${index + 1}`"
                      v-model="pruefposition.Chargennummer"
                      placeholder="#####"
                    />
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Reklamationsgrund -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Reklamationsgrund"
                    :label-for="`pruefposition-reklamationsgrund-${index + 1}`"
                  >
                    <b-form-input
                      :id="`pruefposition-reklamationsgrund-${index + 1}`"
                      v-model="pruefposition.Reklamationsgrund"
                      placeholder="Reklamationsgrund"
                    />
                  </b-form-group>
                </b-col>

                <!-- Verfügbarkeit -->
                <b-col cols="12" md="12">
                  <b-form-group
                    label="Art der Tätigkeit"
                    :label-for="`pruefposition-taetigkeit-${index + 1}`"
                  >
                    <div
                      class="taetigkeiten-group"
                      :id="`pruefposition-taetigkeit-${index + 1}`"
                    >
                      <b-form-checkbox
                        :id="`pruefposition-taetigkeit-kontrolle-pruefen-${
                          index + 1
                        }`"
                        v-model="pruefposition.Taetigkeit.Kontrolle_Pruefen"
                        :name="`pruefposition-taetigkeit-kontrolle-pruefen-${
                          index + 1
                        }`"
                      >
                        Kontrolle/Prüfen
                      </b-form-checkbox>
                      <b-form-checkbox
                        :id="`pruefposition-taetigkeit-nacharbeit-${index + 1}`"
                        v-model="pruefposition.Taetigkeit.Nacharbeit"
                        :name="`pruefposition-taetigkeit-nacharbeit-${
                          index + 1
                        }`"
                      >
                        Nacharbeit
                      </b-form-checkbox>
                      <b-form-checkbox
                        :id="`pruefposition-taetigkeit-stichprobe-${index + 1}`"
                        v-model="pruefposition.Taetigkeit.Stichprobe"
                        :name="`pruefposition-taetigkeit-stichprobe-${
                          index + 1
                        }`"
                      >
                        Stichprobe
                      </b-form-checkbox>
                      <b-form-checkbox
                        :id="`pruefposition-taetigkeit-umpacken-komplettieren-${
                          index + 1
                        }`"
                        v-model="
                          pruefposition.Taetigkeit.Umpacken_Komplettieren
                        "
                        :name="`pruefposition-taetigkeit-umpacken-komplettieren-${
                          index + 1
                        }`"
                      >
                        Umpacken/Komplettieren
                      </b-form-checkbox>
                      <b-form-checkbox
                        :id="`pruefposition-taetigkeit-kontrolle-mit-nacharbeit-${
                          index + 1
                        }`"
                        v-model="
                          pruefposition.Taetigkeit.Kontrolle_mit_Nacharbeit
                        "
                        :name="`pruefposition-taetigkeit-kontrolle-mit-nacharbeit-${
                          index + 1
                        }`"
                      >
                        Kontrolle mit Nacharbeit
                      </b-form-checkbox>
                      <b-form-input
                        :id="`pruefposition-taetigkeit-sonstiges-${index + 1}`"
                        v-model="pruefposition.Taetigkeit.Sonstiges"
                        placeholder="Sonstige Tätigkeit"
                      />
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Stückzahl -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Stückzahl"
                    :label-for="`pruefposition-stueckzahl-${index + 1}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Auftragsnummer"
                      vid="auftragsnummer"
                      rules="integer"
                    >
                      <b-form-input
                        :id="`pruefposition-stueckzahl-${index + 1}`"
                        v-model="pruefposition.Stueckzahl"
                        placeholder="2000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Folgelieferung Prüfen -->
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Folgelieferung Prüfen"
                    :label-for="`pruefposition-folgelieferung-pruefen-${
                      index + 1
                    }`"
                  >
                    <b-form-checkbox
                      class="mt-1"
                      :id="`pruefposition-folgelieferung-pruefen-${index + 1}`"
                      v-model="pruefposition.Folgelieferung_Pruefen"
                      :name="`pruefposition-folgelieferung-pruefen-${
                        index + 1
                      }`"
                    >
                      Folgelieferung Prüfen
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Pruefposition - Auszuführende Tätigkeit -->
                <b-col cols="12" md="12">
                  <b-form-group
                    label="Auszuführende Tätigkeit"
                    :label-for="`pruefposition-auszufuehrende-taetigkeit-${
                      index + 1
                    }`"
                  >
                    <b-form-textarea
                      :id="`pruefposition-auszufuehrende-taetigkeit-${
                        index + 1
                      }`"
                      v-model="pruefposition.Auszufuehrende_Taetigkeit"
                      :name="`pruefposition-auszufuehrende-taetigkeit-${
                        index + 1
                      }`"
                      placeholder="Beschreibung der auszuführenden Tätigkeit"
                    >
                      Folgelieferung Prüfen
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  v-if="
                    pruefpositionenLightbox.length > 0 && pruefposition.Anhaenge
                  "
                >
                  <b-form-group
                    label="Bestehende Detailbilder"
                    :label-for="`pruefposition-bestehende-detailbilder-${
                      index + 1
                    }`"
                  >
                    <b-row class="mt-50">
                      <b-col
                        cols="1"
                        v-for="(img, imgIndex) in pruefpositionenLightbox[index]
                          .imgThumbnailUrls"
                        :key="imgIndex"
                        @click="showLightbox(index, imgIndex)"
                      >
                        <b-aspect
                          aspect="1:1"
                          class="d-flex align-items-center bg-light p-1 position-relative"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-delete-detail-image position-absolute"
                            @click.stop="deleteDetailImage(index, imgIndex)"
                          >
                            <feather-icon icon="TrashIcon" class="mr-25" />
                          </b-button>
                          <b-img-lazy
                            fluid
                            class="detail-image"
                            :src="`${img}`"
                          />
                        </b-aspect>
                      </b-col>
                      <vue-easy-lightbox
                        escDisabled
                        moveDisabled
                        :visible="
                          pruefpositionenLightbox[index].lightboxVisible
                        "
                        :imgs="pruefpositionenLightbox[index].imgUrls"
                        :index="pruefpositionenLightbox[index].lightboxIndex"
                        @hide="hideLightbox(index)"
                      />
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="newDetailImages[index]">
                  <b-form-group
                    label="Neue Detailbilder"
                    :label-for="`pruefposition-neue-detailbilder-${index + 1}`"
                  >
                    <b-form-file
                      :id="`pruefposition-neue-detailbilder-${index + 1}`"
                      v-model="newDetailImages[index].files"
                      accept="image/*"
                      placeholder="Bilder auswählen ..."
                      drop-placeholder="Bilder hierher ziehen ..."
                      multiple
                      @input="makeNewDetailImageUrls(index)"
                    />
                    <b-row class="mt-1">
                      <b-col
                        cols="1"
                        v-for="(img, imgIndex) in newDetailImages[index].urls"
                        :key="imgIndex"
                      >
                        <b-aspect
                          aspect="1:1"
                          class="d-flex align-items-center bg-light p-1 position-relative"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-delete-detail-image position-absolute"
                            @click.stop="deleteNewDetailImage(index, imgIndex)"
                          >
                            <feather-icon icon="TrashIcon" class="mr-25" />
                          </b-button>
                          <b-img-lazy
                            fluid
                            class="detail-image"
                            :src="`${img}`"
                          />
                        </b-aspect>
                      </b-col>
                      <!-- <vue-easy-lightbox
                        escDisabled
                        moveDisabled
                        :visible="
                          pruefpositionenLightbox[index].lightboxVisible
                        "
                        :imgs="pruefpositionenLightbox[index].imgUrls"
                        :index="pruefpositionenLightbox[index].lightboxIndex"
                        @hide="hideLightbox(index)"
                      /> -->
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="12" md="4" class="mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addPruefposition"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Pruefposition hinzufügen</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <h4>Mitarbeiter</h4>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Mitarbeiter"
              label-for="mitarbeiter"
              class="mitarbeiter-selection"
            >
              <v-select
                id="mitarbeiter"
                v-model="orderData.Mitarbeiter"
                multiple
                :options="employees"
                :reduce="employee => employee.id"
                label="Nachname"
                placeholder="Mitarbeiter auswählen"
              >
                <template slot="selected-option" slot-scope="option">
                  <b-row class="selected-employee">
                    <b-col cols="7">
                      <b-media
                        vertical-align="center"
                        class="align-items-center"
                      >
                        <template #aside>
                          <b-avatar
                            class="mitarbeiter-avatar"
                            size="40"
                            :src="
                              option.User.avatar
                                ? $dbBaseUrl +
                                  option.User.avatar.formats.thumbnail.url
                                : ''
                            "
                            :text="`${option.Vorname.charAt()}${option.Nachname.charAt()}`"
                            :variant="`light-secondary`"
                            :to="{
                              name: 'mitarbeiter-ansicht',
                              params: { id: option.id },
                            }"
                            rounded
                          />
                        </template>
                        <p class="mb-0">
                          {{ `${option.Vorname} ${option.Nachname}` }}
                        </p>
                        <div>
                          <b-badge
                            class="mr-50"
                            :variant="rating[1][option.Bewertung]"
                          >
                            {{ option.Bewertung }}
                          </b-badge>
                          <b-badge
                            :variant="
                              status[1][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            "
                          >
                            {{
                              status[0][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            }}
                          </b-badge>
                        </div>
                      </b-media>
                    </b-col>
                    <b-col md="5" class="employee-skills">
                      <p
                        class="mb-0"
                        v-if="option.Kenntnisse.Qualitaetssicherung"
                      >
                        Qualitätssicherung
                      </p>
                      <p class="mb-0" v-if="option.Kenntnisse.Messmittel">
                        Messmittel
                      </p>
                    </b-col>
                  </b-row>
                </template>
                <template slot="option" slot-scope="option">
                  <b-row>
                    <b-col cols="7">
                      <b-media
                        vertical-align="center"
                        class="align-items-center"
                      >
                        <template #aside>
                          <b-avatar
                            class="mitarbeiter-avatar"
                            size="40"
                            :src="
                              option.User.avatar
                                ? $dbBaseUrl +
                                  option.User.avatar.formats.thumbnail.url
                                : ''
                            "
                            :text="`${option.Vorname.charAt()}${option.Nachname.charAt()}`"
                            :variant="`light-secondary`"
                            :to="{
                              name: 'mitarbeiter-ansicht',
                              params: { id: option.id },
                            }"
                            rounded
                          />
                        </template>
                        <p class="mb-0">
                          {{ `${option.Vorname} ${option.Nachname}` }}
                        </p>
                        <div>
                          <b-badge
                            class="mr-50"
                            :variant="rating[1][option.Bewertung]"
                          >
                            {{ option.Bewertung }}
                          </b-badge>
                          <b-badge
                            :variant="
                              status[1][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            "
                          >
                            {{
                              status[0][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            }}
                          </b-badge>
                        </div>
                      </b-media>
                    </b-col>
                    <b-col md="5" class="employee-skills">
                      <p
                        class="mb-0"
                        v-if="option.Kenntnisse.Qualitaetssicherung"
                      >
                        Qualitätssicherung
                      </p>
                      <p class="mb-0" v-if="option.Kenntnisse.Messmittel">
                        Messmittel
                      </p>
                    </b-col>
                  </b-row>
                </template>
                <template #no-options="{ search, searching, loading }">
                  {{ `Es wurden leider keine Mitarbeiter gefunden.` }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-checkbox
          class="mb-50"
          :id="`reporting-documentation`"
          v-model="reportingToggle"
          :name="`reporting-documentation`"
          switch
        >
          Reporting und Dokumentation?
        </b-form-checkbox>
        <b-row v-if="reportingToggle">
          <!-- Reporting/Dokumentation -->
          <b-col cols="12">
            <h4>Reporting/Dokumentation</h4>
          </b-col>

          <!-- Reporting/Dokumentation - Format -->
          <b-col cols="12" md="4">
            <b-form-group label="Format" :label-for="`reporting-format`">
              <div class="reporting-format-group" :id="`reporting-format`">
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-format-email`"
                  v-model="orderData.Reporting.Format_Email"
                  :name="`reporting-format-email`"
                >
                  Email
                </b-form-checkbox>
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-format-telefon`"
                  v-model="orderData.Reporting.Format_Telefon"
                  :name="`reporting-format-telefon`"
                >
                  Telefon
                </b-form-checkbox>
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-format-fax`"
                  v-model="orderData.Reporting.Format_Fax"
                  :name="`reporting-format-fax`"
                >
                  Fax
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>

          <!-- Reporting/Dokumentation - Art -->
          <b-col cols="12" md="4">
            <b-form-group label="Art" :label-for="`reporting-art`">
              <div class="reporting-art-group" :id="`reporting-art`">
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-art-tagesbericht`"
                  v-model="orderData.Reporting.Tagesbericht"
                  :name="`reporting-art-tagesbericht`"
                >
                  Tagesbericht
                </b-form-checkbox>
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-art-auswertung`"
                  v-model="orderData.Reporting.Auswertung"
                  :name="`reporting-art-auswertung`"
                >
                  Auswertung
                </b-form-checkbox>
                <b-form-checkbox
                  class="mt-50"
                  :id="`reporting-art-foto-filmdokumentation`"
                  v-model="orderData.Reporting.Foto_Filmdokumentation"
                  :name="`reporting-art-foto-filmdokumentation`"
                >
                  Foto-/Filmdokumentation
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>

          <!-- Reporting/Dokumentation - Frequenz -->
          <b-col cols="12" md="4">
            <b-form-group label="Frequenz" label-for="status">
              <v-select
                id="status"
                height="20"
                v-model="orderData.Reporting.Frequenz"
                :reduce="status => status.value"
                placeholder="Frequenz auswählen"
                :options="[
                  { label: 'Täglich', value: 'Taeglich' },
                  { label: 'Wöchentlich', value: 'Woechentlich' },
                  { label: 'Monatlich', value: 'Monatlich' },
                ]"
              />
            </b-form-group>
          </b-col>

          <!-- Reporting/Dokumentation - Empfänger -->
          <b-col cols="12" md="4">
            <b-form-group label="Empfaenger" label-for="reporting-empfaenger">
              <b-form-input
                id="reporting-empfaenger"
                v-model="orderData.Reporting.Empfaenger"
                placeholder="Email, Telefonnummer, Faxnummer"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
      </b-form>
    </validation-observer>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateOrder"
    >
      Änderungen Speichern
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="abort"
    >
      Abbrechen
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BBadge,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de';
import de from 'vee-validate/dist/locale/de.json';
import { between, integer, required } from 'vee-validate/dist/rules';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueEasyLightbox from 'vue-easy-lightbox';

extend('between', between);
extend('integer', integer);
extend('required', required);
localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BFormFile,
    BBadge,
    BImgLazy,
    BAspect,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    VueEasyLightbox,
  },
  directives: {
    Ripple,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customers: [],
      employees: [],
      reportingToggle: false,
      locationToggle: false,
      orderDateRange: '',
      flatPickrConfig: {
        mode: 'range',
        enableTime: true,
        defaultHour: 7,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'd.m.Y, H:i \\U\\h\\r',
        allowInput: true,
        time_24hr: true,
        locale: German,
      },
      status: [
        {
          1: 'Im Auftrag',
          2: 'Verfügbar',
          3: 'Krank',
          4: '-',
        },
        {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'secondary',
        },
      ],
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
      pruefpositionenLightbox: [],
      newDetailImages: [],
      newDetailImagesUrls: [],
      detailImageDeleteList: [],
    };
  },
  computed: {
    ansprechpartner() {
      const selectedCustomer = this.customers.find(
        customer => customer.id === this.orderData.Kunde.id
      );
      if (selectedCustomer?.Ansprechpartner) {
        return selectedCustomer.Ansprechpartner;
      }
      return [];
    },
  },
  watch: {
    'orderData.Kunde.id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const selectedCustomer = this.customers.find(
          customer => customer.id === this.orderData.Kunde.id
        );

        this.orderData.Ansprechpartner_ID =
          selectedCustomer.Ansprechpartner[0].id;
      }
    },
    reportingToggle(newValue) {
      if (newValue && this.orderData.Reporting === null) {
        this.orderData.Reporting = {};
      }
    },
    locationToggle(newValue) {
      if (newValue && this.orderData.Abweichender_Auftragsort === null) {
        this.orderData.Abweichender_Auftragsort = {};
      }
    },
  },
  async created() {
    // await this.getOrderData();
    await this.getCustomers();
    await this.getEmployees();
    this.checkInitialReportingStatus();
    this.orderDateRange = `${this.orderData.Auftragskonditionen.Startdatum}${
      this.orderData.Auftragskonditionen.Startzeit
        ? ` ${new Date(
            `${this.orderData.Auftragskonditionen.Startdatum}T${this.orderData.Auftragskonditionen.Startzeit}Z`
          ).toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          })}`
        : ''
    }${
      this.orderData.Auftragskonditionen.Enddatum
        ? ` bis ${this.orderData.Auftragskonditionen.Enddatum}`
        : ''
    }`;
    this.setDetailImages();
  },
  methods: {
    showLightbox(pruefpositionIndex, imgIndex) {
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxVisible = true;
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxIndex = imgIndex;
    },
    hideLightbox(pruefpositionIndex) {
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxVisible = false;
    },
    makeNewDetailImageUrls(pruefpositionIndex) {
      if (this.newDetailImages[pruefpositionIndex].files.length === 0) {
        this.newDetailImages[pruefpositionIndex].urls = [];
      } else {
        this.newDetailImages[pruefpositionIndex].files.forEach(image => {
          base64Encode(image).then(value => {
            this.newDetailImages[pruefpositionIndex].urls.push(value);
          });
        });
      }
    },
    checkInitialReportingStatus() {
      if (this.orderData.Reporting) {
        this.reportingToggle = true;
      }
    },
    checkInitialLocationStatus() {
      if (this.orderData.Abweichender_Auftragsort) {
        this.locationToggle = true;
      }
    },
    async updateOrder() {
      this.$refs.updateOrderForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }
        const updatedOrderData = { ...this.orderData };

        if (!this.reportingToggle) {
          updatedOrderData.Reporting = {};
        }
        if (!this.locationToggle) {
          updatedOrderData.Abweichender_Auftragsort = {};
        }
        if (updatedOrderData.Mitarbeiter.length > 0) {
          updatedOrderData.Mitarbeiter.forEach((mitarbeiter, index) => {
            if (typeof mitarbeiter === 'object') {
              updatedOrderData.Mitarbeiter[index] = { id: mitarbeiter.id };
            } else {
              updatedOrderData.Mitarbeiter[index] = { id: mitarbeiter };
            }
          });
        }

        // Delete Detail Pictures that are deleted from Pruefpositions
        if (this.detailImageDeleteList.length > 0) {
          this.detailImageDeleteList.forEach(async imgId => {
            await this.$http
              .delete(`/upload/files/${imgId}`)
              .catch(err => console.log(err.response.data.error.message));
          });
        }

        // Upload new Detail Pictures
        this.newDetailImages.forEach((pruefposition, index) => {
          if (pruefposition.files.length > 0) {
            pruefposition.files.forEach(async file => {
              const formData = new FormData();
              formData.append('field', 'Anhaenge');
              formData.append('ref', 'api::pruefposition.pruefposition');
              formData.append(
                'refId',
                updatedOrderData.Pruefpositionen[index].id
              );
              formData.append('files', file, file.name);

              await this.$http
                .post('/upload', formData)
                .catch(err => console.log(err.response.data.error.message));
            });
          }
        });

        const pruefpositionenArray = [];
        // Set Pruefpositionen
        if (updatedOrderData.Pruefpositionen.length > 0) {
          await Promise.all(
            updatedOrderData.Pruefpositionen.map(async pruefposition => {
              if (pruefposition?.new) {
                await this.$http
                  .post('/pruefpositionen', { data: pruefposition })
                  .then(response => {
                    pruefpositionenArray.push({ id: response.data.data.id });
                  })
                  .catch(err => console.log(err.response.data.error.message));
              } else {
                await this.$http
                  .put(`/pruefpositionen/${pruefposition.id}`, {
                    data: pruefposition,
                  })
                  .then(() => {
                    pruefpositionenArray.push({ id: pruefposition.id });
                  })
                  .catch(err => console.log(err.response.data.error.message));
              }
            })
          );
        }

        updatedOrderData.Pruefpositionen = pruefpositionenArray;

        // Set Auftragszeitraum
        [
          this.orderData.Auftragskonditionen.Startdatum,
          this.orderData.Auftragskonditionen.Enddatum,
        ] = this.orderDateRange.split(' bis ');

        // Set Auftragszeit
        const date = new Date(this.orderData.Auftragskonditionen.Startdatum);

        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        this.orderData.Auftragskonditionen.Startzeit = `${hours}:${minutes}:${seconds}.${milliseconds}`;

        // Set Order Data
        await this.$http
          .put(`/auftraege/${updatedOrderData.id}`, { data: updatedOrderData })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Auftrag aktualisiert`,
                icon: 'SaveIcon',
                variant: 'success',
                text: `Der Auftrag #${this.orderData.Auftragsnummer} wurde erfolgreich aktualisiert.`,
              },
            });
            this.$router.push({
              name: 'auftraege',
              // name: 'auftraege-ansicht',
              // params: { id: this.$route.params.id },
            });
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Anlegen des Auftrags fehlgeschlagen!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Der Auftrag konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
              },
            });
            console.log(err.response.data.error.message);
          });
      });
    },
    abort() {
      this.$router.push({
        name: 'auftrag-details',
        params: { id: this.$route.params.id },
      });
    },
    addPruefposition() {
      this.orderData.Pruefpositionen.push({
        Bauteilgruppe: null,
        Bezeichnung: null,
        Artikelnummer: null,
        Chargennummer: null,
        Status: 'Bereit',
        Reklamationsgrund: null,
        Auszufuehrende_Taetigkeit: null,
        Stueckzahl: null,
        Folgelieferung_Pruefen: false,
        Taetigkeit: {
          Kontrolle_Pruefen: false,
          Nacharbeit: false,
          Stichprobe: false,
          Umpacken_Komplettieren: false,
          Kontrolle_mit_Nacharbeit: false,
          Sonstiges: null,
        },
        Anhaenge: null,
        new: true,
      });
      this.newDetailImages.push({
        files: [],
        urls: [],
      });
    },
    deletePruefposition(index) {
      this.orderData.Pruefpositionen.splice(index, 1);
      this.newDetailImages.splice(index, 1);
    },
    setDetailImages() {
      this.orderData.Pruefpositionen.forEach(
        (pruefposition, pruefpositionIndex) => {
          this.pruefpositionenLightbox.push({
            imgUrls: [],
            imgThumbnailUrls: [],
            lightboxIndex: 0,
            lightboxVisible: false,
          });
          this.newDetailImages.push({
            files: [],
            urls: [],
          });
          if (pruefposition.Anhaenge != null) {
            pruefposition.Anhaenge.forEach(anhang => {
              this.pruefpositionenLightbox[pruefpositionIndex].imgUrls.push(
                `${this.$dbBaseUrl}${anhang.url}`
              );
              this.pruefpositionenLightbox[
                pruefpositionIndex
              ].imgThumbnailUrls.push(
                `${this.$dbBaseUrl}${anhang.formats.thumbnail.url}`
              );
            });
          }
        }
      );
    },
    deleteDetailImage(pruefpositionIndex, imgIndex) {
      this.pruefpositionenLightbox[pruefpositionIndex].imgUrls.splice(
        imgIndex,
        1
      );
      this.pruefpositionenLightbox[pruefpositionIndex].imgThumbnailUrls.splice(
        imgIndex,
        1
      );
      this.detailImageDeleteList.push(
        this.orderData.Pruefpositionen[pruefpositionIndex].Anhaenge[imgIndex].id
      );
      this.orderData.Pruefpositionen[pruefpositionIndex].Anhaenge.splice(
        imgIndex,
        1
      );
    },
    deleteNewDetailImage(pruefpositionIndex, imgIndex) {
      this.newDetailImages[pruefpositionIndex].files.splice(imgIndex, 1);
      this.newDetailImages[pruefpositionIndex].urls.splice(imgIndex, 1);
    },
    async getCustomers() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 100,
          },
          fields: ['Firma'],
          populate: {
            Ansprechpartner: { fields: ['Vorname', 'Nachname'] },
            User: {
              fields: ['id'],
              populate: { avatar: { fields: ['formats'] } },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let customerItems = [];

      await this.$http
        .get(`/kunden?${query}`)
        .then(response => {
          customerItems = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.customers = customerItems;
    },
    async getEmployees() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 100,
          },
          fields: ['Vorname', 'Nachname', 'Verfuegbar', 'Bewertung'],
          populate: {
            User: {
              fields: ['id', 'email'],
              populate: { avatar: { fields: ['formats'] } },
            },
            Kenntnisse: { fields: '*' },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let employeeItems = [];

      await this.$http
        .get(`/mitarbeiters?${query}`)
        .then(response => {
          employeeItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.employees = employeeItems;
    },
    async getOrderData() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: [
            'id',
            'Auftragsnummer',
            'Bestellnummer',
            'Status',
            'Ansprechpartner_ID',
            'Auftrag_Bei_Pruefanweisung',
            'Auftrag_Bei_Muster',
            'Auftrag_Bei_Sonstige',
          ],
          populate: {
            Mitarbeiter: {
              fields: ['Vorname', 'Nachname'],
              populate: {
                User: {
                  fields: ['email'],
                  populate: {
                    avatar: {
                      fields: ['formats'],
                    },
                  },
                },
                Kontakt: {
                  populate: '*',
                  fields: ['Mobil', 'Telefon'],
                },
              },
            },
            Kunde: {
              fields: ['Firma'],
              populate: {
                Ansprechpartner: {
                  fields: [
                    'Vorname',
                    'Nachname',
                    'Position',
                    'Abteilung',
                    'Email',
                  ],
                },
                Kontakt: {
                  fields: ['Telefon', 'Fax'],
                },
                Anschrift: {
                  fields: '*',
                },
              },
            },
            Auftragskonditionen: {
              fields: '*',
            },
            Pruefpositionen: {
              fields: [
                'Stueckzahl',
                'Bauteilgruppe',
                'Bezeichnung',
                'Folgelieferung_Pruefen',
                'Status',
              ],
            },
            Abweichender_Auftragsort: {
              fields: '*',
            },
            Reporting: {
              fields: '*',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege/${this.$route.params.id}?${query}`)
        .then(response => {
          this.orderData = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.taetigkeiten-group,
.reporting-format-group,
.reporting-art-group {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}
.taetigkeiten-group .custom-checkbox {
  margin-right: 2rem;
}
.reporting-format-group .custom-checkbox,
.reporting-art-group .custom-checkbox {
  margin-right: 2rem;
}
.taetigkeiten-group input {
  width: 18rem;
}
.btn-delete-pruefposition {
  top: -0.5rem;
  right: -0.5rem;
}
.btn-delete-detail-image {
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.5rem;
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.vs__selected-options .selected-employee {
  width: 26.5rem;
}
.mitarbeiter-selection .v-select .vs__selected {
  padding: 0.5rem 0.75rem;
  background-color: #206898dd;
}
.mitarbeiter-selection .v-select .vs__deselect svg path {
  fill: #ea5455;
}
.b-aspect {
  background-color: white;
  transition: all 0.25s ease-in;
  cursor: pointer;
}
.b-aspect:hover {
  transform: scale(1.05);
}
.detail-image {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
