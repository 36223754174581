<template>
  <component :is="orderData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">Fehler bei der Abfrage der Daten</h4>
      <div class="alert-body">
        Es wurde kein Auftrag mit dieser ID gefunden. Gehen Sie bitte zurück zur
        <b-link class="alert-link" :to="{ name: 'auftraege' }">
          Auftragsliste
        </b-link>
        und wählen Sie einen anderen Auftrag aus.
      </div>
    </b-alert>

    <b-row v-if="orderData">
      <b-col>
        <auftrag-bearbeiten-card :orderData="orderData" />
      </b-col>
    </b-row>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BRow, BCol } from 'bootstrap-vue';
import AuftragBearbeitenCard from '@/layouts/components/widgets/AuftragBearbeitenCard.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    AuftragBearbeitenCard,
  },
  data() {
    return {
      orderData: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Page Title
    to.meta.pageTitle = `Auftrag bearbeiten`;
    next();
  },
  async created() {
    const qs = require('qs');
    const query = qs.stringify(
      {
        populate: {
          Auftragskonditionen: {
            populate: '*',
          },
          Abweichender_Auftragsort: {
            populate: '*',
          },
          Reporting: {
            populate: '*',
          },
          Kunde: {
            fields: ['id'],
          },
          Pruefpositionen: {
            populate: '*',
          },
          Mitarbeiter: {
            fields: ['Vorname', 'Nachname', 'Verfuegbar', 'Bewertung'],
            populate: {
              User: {
                fields: ['id', 'email'],
                populate: { avatar: { fields: ['formats'] } },
              },
              Kenntnisse: { fields: '*' },
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
        indices: false,
      }
    );
    await this.$http
      .get(`/auftraege/${this.$route.params.id}?${query}`)
      .then(response => {
        this.orderData = response.data.data;
      })
      .catch(err => console.log(err));

    // Change Page Title
    this.$route.meta.pageTitle = `Auftrag #${this.orderData.Auftragsnummer} bearbeiten`;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
  },
};
</script>

<style></style>
